<template>
  <div>
    <div class="w-full flex flex-row pb-2">
      <strong class="text-lg flex">Checklist de Revisión</strong>
    </div>
    <div class="block w-full overflow-x-auto checklist-items">
      <table class="items-center w-full border-collapse">
        <tbody>
        <tr v-for="item in items" :key="item.checklistId" class="hover:bg-gray-100 hover:shadow">
          <td class="p-2 border-t w-1/12">
            <el-checkbox v-model="item.value" />
          </td>
          <td class="p-2 border-t w-11/12">{{ item.name }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style scoped>
.checklist-items {
  column-count: 2;
}
</style>
<script>
export default {
  name: 'ChecklistItemsList',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
